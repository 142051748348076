.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: var(--big-gap);
  height: auto;

  /* -160px to allow for the 80px margins running down both sides of the page */
  width: calc(100vw - 160px);

  /* center-align content between bottom viewport and bottom of navbar */
  padding-top: calc(88px + var(--big-gap));

  /* keep above binary lines */
  position: relative;
  z-index: 2;
}

.projectsTitle {
  display: none;
}

.project {
  align-self: flex-start;
}

.project img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.comingSoon img {
  /* filter: blur(4px); */
  /* transform: scaleX(0.97); */
}

.project h3 {
  margin-top: var(--small-gap);
  text-align: center;
}

.subtitle {
  text-align: center;
  color: var(--secondary-color-faded);
}

.subtitleLine {
  margin: var(--small-gap) 0;
  border-bottom: 1.5px solid var(--secondary-color);
}

.project span {
  color: var(--accent-color);
}

.notFirstParagraph {
  margin-top: var(--small-gap);
}

.project a,
.finalLine {
  margin-top: var(--small-gap);
  display: flex;
  justify-content: flex-end;
}
.project a {
  cursor: pointer;
}

/*///////////////////////////*/
/* media queries */
@media only screen and (max-width: 1070px) {
  .container {
    grid-template-columns: 1fr;
    scroll-snap-align: none;
    row-gap: calc(var(--big-gap) + var(--big-gap) + var(--big-gap));

    /* center-align content between bottom viewport and bottom of navbar */
    padding-top: calc(68px + var(--big-gap));
  }

  .projectsTitle {
    display: inline-block;
    text-align: center;
    margin-bottom: calc(var(--big-gap) - var(--big-gap) - var(--big-gap) - var(--big-gap));
    border-bottom: 1.5px solid var(--secondary-color);
  }
}

@media only screen and (max-width: 770px) {
  .container {
    /* -40px to allow for the 20px margins running down both sides of the page */
    width: calc(100vw - 40px);
  }

  .comingSoon img {
    /* stop blurred edges from going past page margins */
    transform: scaleX(0.96);
  }
}