.navbar {
  background-color: #00000000;
  padding: 16px 0;
  border-bottom: 1.5px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  box-sizing: border-box;
  transition: background-color 500ms;

  /* stick to top, keep edges in line with rest of content */
  position: fixed;
  z-index: 3;
  width: calc(100vw - 160px);
}

.navbar h3 {
  color: var(--primary-color);
  cursor: pointer;

  /* outline the text */
  text-shadow: -1px -1px 0 var(--secondary-color), 1px -1px 0 var(--secondary-color), -1px 1px 0 var(--secondary-color), 1px 1px 0 var(--secondary-color);
}

.showOnDesktop {
  display: flex;
  gap: 24px;
  align-items: center;
}

.socials {
  /* make container same height as svgs, so it's all center-aligned in the navbar */
  height: 24px;
}
.socials svg {
  fill: var(--secondary-color);
  height: 24px;
  width: 24px;
  margin-left: 12px;
}
/* when the parent is hovered on, the child will change */
.socials svg:hover g {
  fill: var(--accent-color);
  transition: fill 250ms;
}
.socials svg g {
  /* make return to default animation slow as well */
  transition: fill 500ms;
}

.showOnDesktop {
  display: flex;
}
.showOnMobile {
  display: none;
}

/*///////////////////////////*/
/* media queries */
@media only screen and (max-width: 770px) {
  .navbar {
    width: calc(100vw - 40px);
  }

  /* normal nav menu */
  .showOnDesktop {
    display: none !important;
  }

  /* hamburger menu */
  .showOnMobile {
    display: inline-block;
    position: relative;
  }
  /* wrapper, to make the spaced border */
  .showOnMobile div {
    position: absolute;
    right: 0;
    padding: 8px;
    border: 1px solid var(--secondary-color);
  }
  /* container/menu box */
  .showOnMobile ul {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: center;
    gap: 32px;
    padding: 32px 8px 32px 64px;
  }
  .showOnMobile * {
    color: var(--primary-color);
    font-weight: bold;
  }
  /* socials */
  .showOnMobile .socials {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
    height: 40px;
  }
  .showOnMobile svg {
    height: 40px;
    width: 40px;
    cursor: pointer;
    fill: var(--primary-color);
    margin: 0;
  }
  .showOnMobile svg:hover path {
    fill: var(--accent-color);
    transition: fill 250ms;
  }
}
