.container {
  height: calc(100vh - 68px);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--big-gap);

  /* -160px to allow for the 80px margins running down both sides of the page */
  width: calc(100vw - 160px);
}

.container form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--small-gap);
  margin-top: var(--small-gap);
}
.container form * {
  color: var(--primary-color);
}
.container form input,
.container form textarea {
  padding: 8px;
  width: 100%;
  border-radius: 0;
  border: none;

  /* let user select what's in the inputs */
  user-select: text !important;

  /* stop inputs from extending past side page margins */
  box-sizing: border-box;
}
.container form input:focus,
.container form textarea:focus {
  outline: 3px solid green;
}
.container form textarea {
  resize: none;
  min-height: 150px;
}
.container form button,
.modal div button {
  padding: 8px 32px;
  outline: none;
  border: none;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  transition: background-color 500ms;
  cursor: pointer;
}
.container form button:hover,
.modal div button:hover {
  padding: 8px 32px;
  outline: none;
  border: none;
  background-color: var(--accent-color);
  transition: background-color 150ms;
}

.container img {
  width: 420px;

  /* stop binary lines showing in front of image */
  z-index: 2;
}

.modal {
  background-color: #000000b1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
.modal div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: var(--small-gap);
  background-color: var(--primary-color);
  text-align: center;
  padding: var(--small-gap);

  /* center div */
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

@media only screen and (max-width: 1070px) {
  .container {
    grid-template-columns: 1fr;
    scroll-snap-align: none;
    justify-content: space-between;
    margin-top: 100px;
  }
  .container img {
    width: 320px;
  }
}

@media only screen and (max-width: 770px) {
  .container {
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: calc(var(--big-gap) + var(--big-gap));

    /* -40px to allow for the 20px margins running down both sides of the page */
    width: calc(100vw - 40px);

    margin-top: 150px;
  }

  .container h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .container img {
    width: 300px;
  }

  .modal div {
    width: calc(100vw - 40px);
    box-sizing: border-box;
  }
}
