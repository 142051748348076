/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&family=Lato:wght@400;700&family=Nunito:wght@400;600;700&display=swap');

/* global variables */
:root {
  --primary-color: #0b0b0b;
  --secondary-color: #f4f4f4;
  --secondary-color-faded: #f4f4f466;
  --secondary-color-faded-more: #f4f4f425;
  --accent-color: #20c20e;
  --accent-color-faded: #20c20e34;

  --big-gap: 32px;
  --small-gap: 16px;
}

/* base styles */
html * {
  margin: 0;
  padding: 0;
  color: var(--secondary-color);
  user-select: none;
}
body {
  background-color: var(--primary-color);
  height: auto;
  padding-left: 0;
  padding-right: 0;
}
h1 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: Lato, Istok Web, sans-serif;
}
h2 {
  font-size: 2rem;
  font-weight: 700;
  font-family: Istok Web, Lato, sans-serif;
}
h3 {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Istok Web, Lato, sans-serif;
}
p,
a,
button,
input,
textarea,
.binary-bg-line {
  font-size: 1rem;
  font-weight: 400;
  font-family: Nunito, Istok Web, Lato, sans-serif;
}
.subtxt {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: Nunito, Istok Web, Lato, sans-serif;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}

/* all text links (i.e. not the social links) */
.link {
  transition: color 500ms;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.link:hover {
  color: var(--accent-color);
  transition: color 250ms;
}
.link svg {
  fill: white;
  height: 16px;
  transition: padding-left 500ms, margin-right 500ms;
}
.link:hover svg {
  padding-left: 12px;
  margin-right: -12px;
  transition: padding-left 250ms, margin-right 250ms;
}

/* used to turn letters green on hover  */
.text-hover-green {
  transition: color 5000ms;
}
.text-hover-green:hover {
  color: var(--accent-color);
  transition: color 0ms;
}

/* the big title in each section below the hero */
.big-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 7rem;
  font-family: Istok Web, Lato, sans-serif;
  color: var(--secondary-color-faded);

  /* when jumped to by a link, stop the header from cutting off this big-title */
  padding-top: 44px;
}

/* project gallery modal */
.projectGalTitleButtonContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.projectGalTitleButtonContainer h3 {
  color: var(--secondary-color);
  text-align: center;
}
.projectGalTitleButtonContainer button {
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--accent-color);
  background: var(--accent-color-faded);
  font-weight: bold;
}
.projectGalImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  /* overflow; */
  display: flex;
  flex-flow: row nowrap;
}
.projectGalImgContainer img {
  max-width: 75vw;
}
@media only screen and (max-width: 770px) {
  .projectGalImgContainer img {
    max-width: 95vw;
  }
}
.projectGalCloseButtonContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.projectGalCloseButtonContainer button {
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--accent-color);
  background: var(--accent-color-faded);
  font-weight: bold;
}

@media only screen and (max-width: 770px) {
  body {
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-height: 800px) {
  html {
    scroll-snap-type: none;
  }
}
