.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 1;
  scroll-snap-align: start;
  width: calc(100vw - 160px);

  /* center from bottom of navbar to bottom of viewport */
  height: calc(100vh - 68px);
  padding-top: 68px;
}

.greeting span {
  text-transform: uppercase;
}
.container h1 {
  margin-bottom: 12px;
  cursor: default;
}
.container h2 {
  margin-top: 2px;
  cursor: default;
}

.container ul {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 48px;
}

/*///////////////////////////*/
/* media queries */
@media only screen and (max-width: 1070px) {
  .container {
    scroll-snap-align: none;
  }
}

@media only screen and (max-width: 770px) {
  .container {
    /* -40px to allow for the 20px margins running down both sides of the page */
    width: calc(100vw - 40px);
  }
  .container ul {
    gap: 24px;
  }
}

@media only screen and (max-width: 390px) {
  /* make links stack nicely */
  .container ul {
    margin-top: 16px;
    flex-flow: column;
    align-items: flex-end;
    gap: 8px;
  }
}
