.container {
  background-color: #00000000;
  padding: 16px 0;
  border-top: 1.5px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 160px);
  height: 68px;
  box-sizing: border-box;

  /* move up into contact section */
  margin-top: -36px;
}

.container p {
  color: var(--secondary-color-faded);
}

/*///////////////////////////*/
/* media queries */
@media only screen and (max-width: 770px) {
  .container {
    width: calc(100vw - 40px);
    margin-top: 80px;
  }

  .container p:last-of-type {
    text-align: right;
  }
}
