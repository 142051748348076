/* wrapper used to stop binary lines from causing scrollbars */
.wrapperForBinaryLines {
  width: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  /* position: relative; */

  /* put page wide padding here instead of on the body tag, so the binary lines can be outside of the side-of-page margins, whilst all the content is still within them */
  padding-left: 80px;
  padding-right: 80px;
}

/* binary bg lines */
.binary-bg-line {
  cursor: default;
  color: var(--accent-color-faded);
  position: absolute;
  top: -25%;
  z-index: -1;

  /* make fade out at end smooth, and color change on scroll smooth too */
  transition: opacity 1000ms, color 1000ms;

  /* animate, take 120s to move from top to bottom, meaning it'll just be nice and slow */
  animation: binary-bg-line-drop 35000ms linear;

  /* #verticalise the text */
  text-orientation: upright;
  writing-mode: vertical-lr;

  /* remove ability to select */
  user-select: none;
}
@keyframes binary-bg-line-drop {
  0% {
    top: -25%;
  }
  100% {
    top: 330%;
  }
}

/*///////////////////////////*/
/* media queries */
@media only screen and (max-width: 1070px) {
  .binary-bg-line {
    top: -10%;
    animation: binary-bg-line-drop 35000ms linear;
  }
  @keyframes binary-bg-line-drop {
    0% {
      top: -10%;
    }
    100% {
      top: 880%;
    }
  }
}
@media only screen and (max-width: 770px) {
  .wrapperForBinaryLines {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container {
    /* -40px to allow for the 20px margins running down both sides of the page */
    width: calc(100vw - 40px);
  }
  .container ul {
    gap: var(--big-gap);
  }
}
