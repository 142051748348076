.container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: center;
  column-gap: var(--big-gap);
  row-gap: var(--big-gap);

  /* -160px to allow for the 80px margins running down both sides of the page */
  width: calc(100vw - 160px);

  /* center-align content between bottom viewport and bottom of navbar */
  padding-top: 68px;
  height: 100vh;
}

.subtitle {
  margin-bottom: var(--small-gap);
}
.subtitleLine {
  margin-bottom: var(--small-gap);
  border-bottom: 1.5px solid var(--secondary-color);
}

.dontShowOnMobile {
  display: block;
}
.showOnMobile {
  display: none;
}

/*///////////////////////////*/
/* big ol avatar img */
.avatar {
  grid-column: 1;
  grid-row: 1 / span 2;
  align-self: center;
  height: 300px;
  border-radius: 50%;
  z-index: 2;

  /* bring up to center of column */
  margin-top: -68px;
}

/*///////////////////////////*/
/* list of skills */
.skills {
  grid-column: 2;
  align-self: flex-end;
}
/* svg container */
.skills ul {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr 1fr auto 1fr auto 1fr 1fr;
  row-gap: var(--small-gap);
  column-gap: var(--small-gap);
  text-align: center;
}
.skills li svg {
  margin-top: 8px;
  height: 80px;
}
/* used to only widen the smaller svgs to the minimum needed width */
.widenLogo {
  width: 80px;
}

/*///////////////////////////*/
/* about me section */
.aboutMe {
  grid-column: 2;
  align-self: flex-start;
}
.aboutMe ul {
  margin-top: var(--small-gap);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--small-gap);
  width: 100%;
}
.notFirstParagraph {
  margin-top: var(--small-gap);
}
.socials {
  /* make container same height as svgs, so it's all center-aligned */
  height: 32px;
}
.socials svg {
  fill: var(--secondary-color);
  height: 32px;
  width: 32px;
}
.socials > a:nth-child(2) > svg:nth-child(1) {
  margin-left: 12px;
}
/* when the parent is hovered on, the child will change */
.socials svg:hover g {
  fill: var(--accent-color);
  transition: fill 250ms;
}
.socials svg g {
  /* make return to default animation slow as well */
  transition: fill 500ms;
}

/*///////////////////////////*/
/* media queries */
@media only screen and (max-width: 1500px) {
  .container {
    row-gap: var(--big-gap);
  }
  .avatar {
    grid-row: 1;
    height: 250px;
    align-self: flex-end;
  }
  .skills {
    grid-column: 2;
    align-self: flex-end;
  }
  .aboutMe {
    grid-column: 1 / span 2;
    align-self: flex-start;
  }
  .skills li svg {
    height: 60px;
  }
  .widenLogo {
    width: 60px;
  }
}

@media only screen and (max-width: 1350px) {
  .container {
    row-gap: 0;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 1fr;
    padding-top: 100px;
    height: 100vh;
  }
  .avatar {
    grid-row: 1;
    height: 250px;
    align-self: center;
    margin-top: 0;
  }
  .skills {
    grid-column: 2;
    align-self: flex-end;
  }
  .aboutMe {
    grid-column: 1 / span 2;
    align-self: flex-start;
  }

  .skills ul {
    grid-template-columns: 1fr auto 1fr auto 1fr;
  }
  .skills li svg {
    height: 60px;
  }
  .widenLogo {
    width: 60px;
  }
}

@media only screen and (max-width: 1070px) {
  .container {
    row-gap: var(--big-gap);
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding-top: 0;
    height: auto;
  }
  .avatar {
    grid-row: 1;
    height: 250px;
    justify-self: center;
    align-self: flex-end;
  }
  .skills {
    grid-row: 2;
    grid-column: 1;
  }
  .aboutMe {
    grid-row: 3;
    grid-column: 1;
    justify-self: center;
  }

  .skills ul {
    grid-template-columns: 1fr auto 1fr auto 1fr;
  }
  .skills li svg {
    height: 50px;
  }
  .widenLogo {
    width: 50px;
  }
}

@media only screen and (max-width: 770px) {
  .container {
    /* -40px to allow for the 20px margins running down both sides of the page */
    width: calc(100vw - 40px);
  }

  .skills ul {
    grid-template-columns: 1fr auto 1fr;
  }

  .dontShowOnMobile {
    display: none;
  }
  .showOnMobile {
    display: block;
  }
}
